$fa-font-path:        'fonts';

$primary:             #93b5c9;
$secondary:           #868e96;
$success:             #008f54;
$info:                #005a85;
$warning:             #fdf314;
$danger:              #dc1200;
$light:               #ffffff;
$dark:                #343a40;

$link-color:          $primary;

$gray-200:            #e9ecef !default;
$border-color:        $gray-200;
$list-inline-padding: 5px;
$dropdown-item-padding-x: 1rem;@import './fontawesome/fontawesome.scss';
  @import './fontawesome/regular.scss';
  @import './fontawesome/solid.scss';
  @import './fontawesome/brands.scss';
@import './bootstrap/bootstrap.scss';
@import './helpers';
@import './header';
@import './faq';
@import './form';
@import './portfolio';
@import './pricing';
@import './stripe';
@import './modal';
@import './search';
@import './toc';
@import './syna';
